import React from 'react';
import Layout from "../../../../components/layouts"
export default class Category extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout>
                <section className="breadcrumb_area_two">
                <ul className="list-unstyled bubble">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
                <div className="breadcrumb_shap"></div>
                <div className="container">
                    <div className="breadcrumb_content_two text-center">
                        <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">Under Construction</h1>

                    </div>
                </div>
                </section>
            </Layout>
        )
    }
}